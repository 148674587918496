<template>
    <div>
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col cols="12" md="12">
                        <b-card no-body>
                            <b-card-header>
                                <h4 v-if="document.Id" class="card-title">{{ i18nT(`Edit Quote`) }}</h4>
                                <h4 v-else class="card-title">{{ i18nT(`Create quote`) }}</h4>
                            </b-card-header>
                            <b-card-body>


                                <!-- Invoice information -->
                                <b-row class="border-bottom pt-2 pb-2" >
                                    <!-- Field: Username -->
                                    <b-col cols="12" md="2">
                                        <h5>
                                            {{ i18nT(`Main information`) }}
                                        </h5>
                                    </b-col>
                                    <b-col cols="12" md="10">
                                        <b-row>
                                            <b-col cols="12" md="4">
                                                <b-form-group
                                                    :label="i18nT(`Project name`)"
                                                    label-for="Number"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Project name`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="document.Label"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="4">
                                                <b-form-group
                                                    :label="i18nT(`Quote number`)"
                                                    label-for="Number"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Quote number`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="document.QuoteNumber"
                                                        />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                                </b-form-group>
                                            </b-col>


                                            <b-col cols="12" md="4">
                                                <b-form-group
                                                    :label="i18nT(`Currency`)"
                                                    label-for="Vendor"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Currency`)"
                                                        rules="required"
                                                    >
                                                        <b-form-select
                                                            v-model="document.Currency"
                                                            :options="currencyList"
                                                            :state="errors.length > 0 ? false:null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                        </b-row>

                                        <!-- VENDOR -->
                                        <b-row>
<!--                                            <b-col cols="12" md="4" >-->
<!--                                                <b-form-group-->
<!--                                                    :label="i18nT(`Vendor`)"-->
<!--                                                    label-for="i18nT(`Vendor`)"-->
<!--                                                >-->
<!--                                                    <b-form-select-->
<!--                                                        v-model="document.VendorId"-->
<!--                                                        :options="vendorList"-->
<!--                                                    />-->
<!--                                                </b-form-group>-->
<!--                                            </b-col>-->
                                            <b-col cols="12" md="4">
                                            <validation-provider #default="{ errors }"
                                                                 :name="i18nT(`Client`)"
                                                                 rules="required">
                                              <b-form-group
                                                  :label="i18nT(`Client`)"
                                                  label-for="Vendor" class="required">
                                                <div class="d-flex">
                                                  <b-input-group-prepend>
                                                    <b-button variant="primary"
                                                              v-b-tooltip="i18nT(`Add new client`)"
                                                              v-b-modal.add-vendor class="corners-straight-right">
                                                      <font-awesome-icon :icon="['fas', 'plus']" />
                                                    </b-button>
                                                  </b-input-group-prepend>

                                                  <b-form-select class="corners-straight-left"
                                                                 v-model="document.VendorId" :options="vendorList" />
                                                  <small class="text-danger">{{ errors[0] }}</small>

                                                </div>


                                                <span class="text-muted font-small-3">
                                                            {{
                                                    i18nT(`Select the client from the list or click on the PLUS icon to create a new one.`) }}
                                                        </span>

                                              </b-form-group>
                                            </validation-provider>
                                          </b-col>

                                            <b-col cols="12" md="4" >
                                                <b-form-group
                                                    :label="i18nT(`Recipient name`)"
                                                    label-for="RecipientName"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Recipient name`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="document.RecipientName"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="4" >
                                                <b-form-group
                                                    :label="i18nT(`Recipient e-mail`)"
                                                    label-for="RecipientEmail"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Recipient e-mail`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="document.RecipientEmail"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>


                                        </b-row>

                                        <!-- Number -->
                                        <b-row class="border-bottom pb-1 mb-1">


                                            <!-- CURRENCY -->

                                        </b-row>


                                        <!-- INVOICE DATES -->
                                        <b-row class="border-bottom pb-2 mb-1">

                                            <b-col cols="12" md="6">
                                                <b-form-group
                                                    :label="i18nT(`Quote date`)"
                                                    label-for="quoteDate"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Quote date`)"
                                                        rules="required"
                                                    >
                                                        <date-picker
                                                            id="quote-datepicker"
                                                            v-model="document.QuoteDate"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" md="6">
                                                <b-form-group
                                                    :label="i18nT(`Expiration date`)"
                                                    label-for="dueDate"
                                                >
                                                    <date-picker
                                                        id="due-datepicker"
                                                        v-model="document.ExpirationDate"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                        </b-row>

                                        <b-row>
                                            <b-col
                                                cols="12" md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Message`)"
                                                    label-for="description"
                                                >
                                                    <b-form-textarea
                                                        id="textarea-default"
                                                        rows="3"
                                                        v-model="document.Description"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12" md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Internal note`)"
                                                    label-for="internalNote"
                                                >
                                                    <b-form-textarea
                                                        id="textarea-default"
                                                        rows="3"
                                                        v-model="document.InternalComments"
                                                    />
                                                    <span class="text-muted">{{i18nT(`Internal notes are confidential and are not visible to your client.`)}}</span>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>

                                        </b-row>
                                    </b-col>
                                </b-row>

                                <!-- Payment Items -->
                                <b-row class="border-bottom">
                                    <b-col cols="12" md="2">
                                        <h5 class="mt-2">
                                            {{ i18nT(`Quote items`) }}
                                        </h5>
                                    </b-col>
                                    <b-col cols="12" md="10">
                                        <div
                                            v-for="(item, index) in paymentItems"
                                            :key="index"
                                            class="border-bottom pb-1 pt-1"
                                        >
                                            <b-row>
                                                <!--ITEM -->
                                                <b-col
                                                    cols="12"
                                                    md="4"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Item description`)"
                                                        label-for="salaryNet"
                                                    >
                                                        <b-form-input
                                                            v-model="item.Label"
                                                            :placeholder="i18nT(`Product or service delivered`)"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                                <!--QUANTITY -->
                                                <b-col
                                                    cols="12"
                                                    md="2"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Qty`)"
                                                        label-for="salaryNet"
                                                    >
                                                        <b-form-input
                                                            v-model="item.Quantity"
                                                            placeholder="1"
                                                            type="number"
                                                        />
                                                    </b-form-group>
                                                </b-col>

                                                <!--UNIT PRICE -->
                                                <b-col
                                                    cols="12"
                                                    md="2"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Unit price`)"
                                                        label-for="salaryNet"
                                                    >
                                                        <b-form-input
                                                            v-model="item.UnitPrice"
                                                            type="number"
                                                        />
                                                    </b-form-group>
                                                </b-col>

                                                <!--DISCOUNT -->
                                                <b-col
                                                    cols="12"
                                                    md="2"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Discount`)"
                                                        label-for="discount"
                                                    >
                                                        <b-form-input
                                                            :value="itemDiscount(index) | money"
                                                            placeholder="1"
                                                            type="number"
                                                            class="mini-paddings"
                                                            readonly
                                                        />
                                                    </b-form-group>
                                                </b-col>

                                                <!--SUBTOTAL -->
                                                <b-col
                                                    cols="12"
                                                    md="2"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Subtotal`)"
                                                        label-for="salaryNet"
                                                    >
                                                        <b-form-input
                                                            :value="itemSubTotal(item, index) | money"
                                                            placeholder="1"
                                                            type="number"
                                                            class="mini-paddings"
                                                            readonly
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>

                                            <b-row>
                                                <b-col
                                                    cols="12"
                                                    md="8"
                                                >
                                                </b-col>

                                                <!--VAT % -->
                                                <b-col
                                                    cols="12"
                                                    md="2"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`VAT (%)`)"
                                                        label-for="VatPercent"
                                                    >
                                                        <b-form-input
                                                            v-model="item.VatPercent"
                                                            type="number"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                                <!--TOTAL -->
                                                <b-col
                                                    cols="12"
                                                    md="2"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Total`)"
                                                        label-for="salaryNet"
                                                    >
                                                        <b-form-input
                                                            :value="item.Total | money"
                                                            disabled
                                                        />
                                                    </b-form-group>
                                                </b-col>

                                            </b-row>
                                            <div class="text-right">
                                                <b-button
                                                    variant="outline-danger"
                                                    @click="removeItem(index)"
                                                >
                                                    <feather-icon icon="MinusCircleIcon"/>
                                                    {{ i18nT(`Remove`) }}
                                                </b-button>
                                            </div>
                                        </div>

                                        <div class="pt-1 mb-2">
                                            <b-button
                                                variant="outline-primary"
                                                @click="addItems"
                                            >
                                                <feather-icon icon="PlusCircleIcon"/>
                                                {{ i18nT(`Add more`) }}
                                            </b-button>
                                        </div>


                                        <!-- DISCOUNT TOGGLE -->
                                        <div class="border-bottom pb-1 pt-1">
                                            <b-row>
                                                <b-col
                                                    cols="12"
                                                    md="4"
                                                    md-offset="8"
                                                >

                                                    <b-form-group
                                                        :label="i18nT(`Discount amount`)"
                                                        label-for="salaryNet"
                                                    >
                                                        <div class="d-flex">
                                                            <b-form-input
                                                                class="corners-straight-right"
                                                                :placeholder="i18nT(`0`)"
                                                                v-model="discountValue"
                                                                type="number"
                                                            />
                                                            <div class="semi-button cursor-pointer"
                                                                 v-b-tooltip="i18nT(`Toggle discount mode`)"
                                                                 @click="toggleDiscountMode()">
                                                                <font-awesome-icon
                                                                    class="d-block text-white ml-2"
                                                                    :icon="discountMode == 'percent' ? ['fa', 'percent'] : ['fa', 'dollar-sign']"></font-awesome-icon>
                                                            </div>
                                                        </div>
                                                    </b-form-group>

                                                </b-col>
                                            </b-row>
                                        </div>
                                        <!-- SUMMARIES -->
                                        <div>
                                            <b-row class="mt-1">
                                                <b-col cols="2">
                                                    {{ i18nT(`Items total`) }}
                                                </b-col>
                                                <b-col cols="10" class="pl-2">
                                                    <strong>{{ {amount: subtotalAll, currency: document.Currency} | currency }}</strong>
                                                </b-col>
                                            </b-row>

                                            <b-row class="mt-1" v-if="discountValue && discountValue > 0">
                                                <b-col cols="2">
                                                    {{ i18nT(`Discount`) }}
                                                </b-col>
                                                <b-col cols="10">
                                                    <strong class="text-danger">- {{  {amount: calcActualDiscount(discountValue) || 0, currency: document.Currency} | currency }}</strong>
                                                </b-col>
                                            </b-row>

                                            <b-row class="mt-1">
                                                <b-col cols="2">
                                                    {{ i18nT(`Subtotal`) }}
                                                </b-col>
                                                <b-col cols="10" class="pl-2">
                                                    <strong>{{ {amount: (subtotalAll-discountAmount), currency: document.Currency} | currency }}</strong>
                                                </b-col>
                                            </b-row>

                                            <b-row class="mt-1">
                                                <b-col cols="2">
                                                    {{ i18nT(`VAT or Tax`) }}
                                                </b-col>
                                                <b-col cols="10" class="pl-2">
                                                    <strong>{{ {amount:totalVAT, currency: document.Currency} | currency }}</strong>
                                                </b-col>
                                            </b-row>

                                            <b-row>
                                                <b-col cols="4">
                                                    <b-row class="mt-1 mb-1 pt-1 border-top">
                                                        <b-col cols="6">
                                                            <h4>{{ i18nT(`Total`) }}</h4>
                                                        </b-col>
                                                        <b-col cols="6" class="pl-2">
                                                            <h4>
                                                                <strong>{{ {amount: finalTotal || 0, currency: document.Currency} | currency}}</strong>
                                                            </h4>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                          <b-row>
                                            <b-col cols="12" md="6">
                                              <b-form-checkbox name="check-button" switch v-model="document.DisplayGeneralConditions">
                                                {{ i18nT(`Add general terms and conditions to your quote`) }}
                                              </b-form-checkbox>
                                              <span class="text-muted font-small-3">{{
                                                  i18nT(`You can define your general terms and conditions in`) }}
                                                <b-link target="_blank" :to="'/ac/company-profile/general-terms'">
                                                  {{i18nT(`Organization profile`) }}
                                                </b-link>
                                              </span>
                                            </b-col>
                                          </b-row>
                                        </div>

                                    </b-col>
                                </b-row>

                                <!--Additional documents DROPZONE-->
                                <b-row class="border-bottom pt-2 pb-2">
                                    <b-col cols="12" md="2">
                                        <h5>
                                            {{ i18nT(`Additional documents`) }}
                                        </h5>
                                    </b-col>
                                    <b-col cols="12" md="10">
                                        <b-row>
                                            <b-col cols="12" md="12" :class="dropzone2HasError ? 'sk-error' : ''">
                                                <vue-dropzone ref="additionalDropzone"
                                                              :duplicateCheck="true"
                                                              @vdropzone-queue-complete="queCompleteMultiple"
                                                              @vdropzone-error="dropzoneErrorMultiple"
                                                              @vdropzone-added="onFileAdded"
                                                              @vdropzone-success="dropzoneSuccessMultiple"
                                                              id="dz-invoice-multiple"
                                                              class="sk-dropzone"
                                                              :options="dropzone2Options"></vue-dropzone>

                                                <div class="mt-2">
                                                    <b-list-group-item
                                                        v-for="(attachedDoc, index) in document.QuoteDocuments"
                                                        :key="attachedDoc.Id"
                                                        tag="li"
                                                    >
                                                        <b-row>
                                                            <b-col cols="6" class="d-flex align-items-center">
                                                                <b-link :href="attachedDoc.DownloadUrl" target="_blank" :download="attachedDoc.Label"><b>{{ attachedDoc.Label }}</b></b-link>
                                                            </b-col>
                                                            <b-col cols="4">
                                                                <label class="text-muted">{{ i18nT(`Document type`) }}</label>
                                                                <b-form-select
                                                                    v-model="document.QuoteDocuments[index].Type"
                                                                    :options="paymentCategoryList">

                                                                </b-form-select>
                                                            </b-col>
                                                            <b-col cols="2" class="d-flex align-items-center justify-content-end">
                                                                <b-button
                                                                    variant="danger"
                                                                    @click="removeAttachedDocument(index)"
                                                                >
                                                                    <feather-icon icon="Trash2Icon" size="16"></feather-icon>
                                                                </b-button>
                                                            </b-col>
                                                        </b-row>
                                                    </b-list-group-item>
                                                </div>

                                            </b-col>
                                        </b-row>

                                    </b-col>

                                </b-row>

                                <b-row>
                                    <div style="flex: 1"/>
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        variant="primary"
                                        class="mt-0 mt-md-2 ml-1 mr-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{ i18nT(`Save`) }}
                                    </b-button>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
      <b-modal id="add-vendor" :title="i18nT(`Add new client`)" size="xl" hide-footer centered :no-close-on-backdrop="true"
               :no-close-on-esc="true">
        <edit-vendor @vendorAdded="onVendorAdded" :modalMode="true" />
      </b-modal>
    </div>

</template>

<script>
import {
  BButton,
  // BAlert,
  // BBadge,
  BRow,
  BCol,
  BFormGroup,
  // BFormRadioGroup,
  BFormInput,
  BForm,
  BCard,
  // BFormCheckbox,
  BFormTextarea,
  BCardBody,
  BFormSelect,
  BListGroupItem,
  BLink,
  VBModal,
  VBTooltip,
  // BTabs,
  // BTab,
  // BFormFile,
  // BInputGroup,
  // BInputGroupText,
  BCardHeader, BInputGroupPrepend, BFormCheckbox,
  // BDropdown,
  // BDropdownItem
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import {dictToSelectArray} from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import useAuth from '@/auth/useAuth';
import _ from 'lodash'
import {getCurrentInstance} from '@vue/composition-api'
import DatePicker from '@core/components/DatePicker.vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import {API_KEY} from "@core/utils/constants";
import EditVendor from "@/views/accounting/Vendors/EditVendor.vue";
//import store from "@/store";

export default {
    components: {
      BFormCheckbox,
      BInputGroupPrepend,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        // BFormRadioGroup,
        BFormInput,
        BForm,
        BCard,
        BCardBody,
        // vSelect,
        BFormTextarea,
        BFormSelect,
        // BAlert,
        // BBadge,
        // BFormCheckbox,
        BListGroupItem,
        BLink,
        // BTabs,
        // BTab,
        ValidationProvider,
        ValidationObserver,
        // BFormFile,
        // BInputGroup,
        // BInputGroupText,
        BCardHeader,
        // BDropdown,
        // BDropdownItem,
        VueDropzone: vue2Dropzone,
        DatePicker,
       EditVendor
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            document: {
                Discount: 0,
                IsQuote: 1,
                QuoteDocuments: [],
                QuoteFile: null,
                VendorId: null,
                DisplayGeneralConditions: 0,
            },
            selectedVendor: {},
            tempQuote: null,
            originalQuote: null,
            file: null,
            fileName: null,
            required,
            email,
            hasUrl: false,
            hasFile: false,
            originalFileName: null,
            vendorList: [],
            typeList: [],

            discountMode: 'percent',
            discountValue: 0,
            discountAmount: 0,
            discounts:[],
            totalVAT: 0,


            paymentCategoryList: [],
            paymentTypeList: [],
            currencyList: [],

            paymentItems: [{}],

            additionalDocs: [
                {
                    Name: '',
                    Url: '',
                },
            ],


            dropzonePopulateFromResp: true,

            dropzone2HasError: false,

            dropzone2Options: {
                url: process.env.VUE_APP_API_URL+'quotes/quoteAttachments',
                addRemoveLinks: true,
                autoProcessQueue: true,
                clickable: true,
                dictDefaultMessage: this.i18nT(`Drag and drop additional files here`),
                dictRemoveFile: this.i18nT(`Remove`),
                //dictRemoveFileConfirmation: this.i18nT(`Are you sure you want to remove this file?`),
                dictFileTooBig: this.i18nT(`File is too big ({{filesize}}MB). Max filesize is: {{maxFilesize}}MB`),
                dictInvalidFileType: this.i18nT(`Only PDFs and JPGs are allowed`),
                dictCancelUpload: this.i18nT(`Cancel`),
                dictCancelUploadConfirmation: this.i18nT(`Are you sure you want to cancel this upload?`),
                dictMaxFilesExceeded: this.i18nT(`You can attach up to 10 files`),
                maxFilesize: 5,
                maxFiles: 10,
                acceptedFiles: 'image/*,application/pdf',
                headers: {
                    'Api-Key': API_KEY,
                    'Auth-Key': useAuth.getToken(),
                },
            },

        }
    },
    created() {
        this.document.Template = router.currentRoute.meta.template === 1
        this.softwares = _.values(useAuth.getSoftwares()).map(software => ({
            value: software.id,
            title: `${software.short.toUpperCase()} - ${software.title}`
        }))
        Promise.all([
            new Promise((res) => {
                this.$http.get(
                    `vendors/vendorsList?clients=1`
                ).then(({data}) => {
                    this.vendorList = dictToSelectArray(data.data)
                    res()
                })
            }),
            new Promise((res) => {
                this.$http.get(
                    `system/financeTypes`
                ).then(({data}) => {
                    this.typeList = dictToSelectArray(data.data)
                    res()
                })
            }),
            new Promise((res) => {
                this.$http.get(
                    `system/paymentTypes`
                ).then(({data}) => {
                    this.paymentTypeList = dictToSelectArray(data.data)
                    res()
                })
            }),

            new Promise((res) => {
                this.$http.get(
                    `system/currencies`
                ).then(({data}) => {
                    this.currencyList = dictToSelectArray(data.data)
                    res()
                })
            }),
            new Promise((res) => {
                this.$http.get(
                    `invoices/categories`
                ).then(({data}) => {
                    this.paymentCategoryList = dictToSelectArray(data.data)
                    res()
                })
            }),



        ]).then(() => {
            if (router.currentRoute.params.id) {
                this.dropzonePopulateFromResp = false;
                this.$http.get(
                    `quotes?id=${router.currentRoute.params.id}`
                ).then(({data}) => {
                    this.postProcessInvoice(data.data, true);
                })
            }
        })
    },
    computed: {

        hasRouteId() {
            return router && router.currentRoute.params.id
        },
        finalSubtotal() {
            return this.subtotalAll - (this.discountValue || 0);
        },
        totalAll(){
            return this.paymentItems.filter(item => !!item.UnitPrice && !!item.Quantity).reduce((acc, item) =>
                    (acc + (item.UnitPrice * item.Quantity * ((item.VatPercent ? item.VatPercent : 0) / 100 + 1))),
                0).toFixed(2) - (this.discountValue || 0)
        },
        subtotalAll(){
            return this.paymentItems.filter(item => !!item.UnitPrice && !!item.Quantity).reduce((acc, item) =>
                    (acc + item.UnitPrice * item.Quantity),
                0).toFixed(2);
        },
        finalTotal() {
            return parseFloat(this.subtotalAll - this.discountAmount) + parseFloat(this.totalVAT);
        },
        vendorId() {
            return this.document.VendorId
        },
    },
    methods: {
        onVendorAdded() {
            this.$bvModal.hide('add-vendor');
            this.$http.get(
                `vendors/vendorsList?clients=1`
            ).then(({ data }) => {
              this.vendorList = dictToSelectArray(data.data)
              this.document.VendorId = this.vendorList[this.vendorList.length - 1].value;
            });
        },
        updateTotalVat(){
            let total = 0;
            let self = this;

            if(this.paymentItems.length !== 0) {
                for (let i = 0; i < self.paymentItems.length; i++) {
                    total += (!!self.paymentItems[i].Quantity && !!self.paymentItems[i].UnitPrice) ?
                        ( (self.paymentItems[i].Quantity * self.paymentItems[i].UnitPrice)  - self.itemDiscount(i) )* (self.paymentItems[i].VatPercent / 100).toFixed(2) : 0;
                }
            }
            this.totalVAT = total;
        },
        updateTotals(itemList){
            let self = this;
            for (let i = 0; i < itemList.length; i++) {
                itemList[i].Total = (!!itemList[i].Quantity && !!itemList[i].UnitPrice) ?
                    ( (itemList[i].Quantity * itemList[i].UnitPrice)  - self.itemDiscount(i) )* (1 + (itemList[i].VatPercent ?? 0) / 100).toFixed(2)  + ""
                    : "0"
            }
            return itemList;
        },
        itemSubTotal(item, index) {
            if(!index){
                index = this.paymentItems.indexOf(item);
            }
            return item.Quantity * item.UnitPrice - this.itemDiscount(index)
        },
        itemDiscount(index){
            return this.discounts[index] ? this.discounts[index] : 0;
        },
        calcActualDiscount() {
            let self = this;

            if(this.discountMode == 'percent') {
                this.discountAmount = this.subtotalAll * this.discountValue / 100;
            }else {
                this.discountAmount = this.discountValue;
            }

            self.paymentItems.forEach(function (item, index) {
                let proportion = item.UnitPrice * item.Quantity / self.subtotalAll;
                self.discounts[index] = self.discountAmount * proportion;
            });

            return this.discountAmount
        },
        toggleDiscountMode() {
            this.discountMode = this.discountMode == 'percent' ? 'amount' : 'percent';
            this.calcActualDiscount();
        },
        dropzoneAddParams(file, xhr, formData) {
            let isClient = this.document.Type === 2 ? 1 : 0;
            formData.append('isClient', isClient);
            this.$store.commit('app/REQUEST_PENDING')
        },

        removeAttachedDocument(index) {
            this.document.QuoteDocuments.splice(index, 1)
        },
        removePrimaryDocument(){
            this.document.QuoteFile = null;
            this.tempQuote = null;
        },
        postProcessInvoice(QuoteData, initialLoad  = false){
            this.document = QuoteData;
            this.document.IsQuote = this.document.IsQuote == 1
            this.paymentItems = [];
            this.paymentItems = this.document.QuoteItems


            if(this.document.DiscountPercent){
                this.discountMode = 'percent';
                this.discountValue = this.document.DiscountPercent;
            } else {
                this.discountMode = 'amount';
                this.discountValue = this.document.DiscountAmount;
            }

            if(this.document.DisplayGeneralConditions === 1) {
              this.document.DisplayGeneralConditions = true;
            } else{
              this.document.DisplayGeneralConditions = false;
            }


            if(!initialLoad){
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT('Quote data updated from attachment'),
                        icon: 'CheckIcon',
                        variant: 'success'
                    }
                })
            }
        },
        dropzoneSuccessMultiple: function (files, response) {
            console.log("Multiple Success: ", files, response);
            this.document.QuoteDocuments.push(response.data);
        },

        dropzoneErrorMultiple(message){
            this.dropzone2HasError = true;
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                timeout: 9000,
                props: {
                    title: this.i18nT(message.previewElement.childNodes[7].innerText),
                    text: message.upload.filename,
                    icon: 'InfoIcon',
                    variant: 'danger',
                    timeout: 20000,
                },
            })
        },
        onFileAdded(file) {
            console.log("File added: ", file);
            //this.$refs.myVueDropzone.processQueue();
        },
        queComplete() {
            this.$refs.mainDropzone.removeAllFiles();
            //this.$refs.myVueDropzone.removeAllFiles();
        },

        queCompleteMultiple() {
            let self = this;

            setTimeout(function () {
                self.$refs.additionalDropzone.removeAllFiles();
                self.dropzone2HasError = false;
            }, 4000);
        },

        onFilePick: function () {
            this.$refs['filePicker'].$refs.input.click()
        },
        onFileDelete: function () {
            this.file = null
            this.fileName = ''
        },
        removeItem: function (index) {
            this.paymentItems.splice(index, 1)
        },
        validationForm() {
            console.log(this.document)
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                  const formData = new FormData()
                  if (router.currentRoute.params.id) {
                    formData.append('id', router.currentRoute.params.id)
                  }

                  if (this.document.DisplayGeneralConditions) {
                    formData.append('DisplayGeneralConditions', 1)
                  } else {
                    formData.append('DisplayGeneralConditions', 0);
                  }

                  if (this.document.QuoteFile) {
                    formData.append('QuoteFileId', this.document.QuoteFile.Id)
                  }
                  formData.append('Label', this.document.Label)
                  formData.append('QuoteNumber', this.document.QuoteNumber)
                  formData.append('Currency', this.document.Currency)

                  formData.append('VendorId', this.document.VendorId)
                  formData.append('RecipientName', this.document.RecipientName)
                  formData.append('RecipientEmail', this.document.RecipientEmail)


                  formData.append('QuoteDate', this.document.QuoteDate)
                  if (this.document.ExpirationDate) {
                    formData.append('ExpirationDate', this.document.ExpirationDate)
                  }


                    formData.append('Description', this.document.Description)
                    formData.append('InternalComments', this.document.InternalComments)
                    formData.append('Discount', this.discountValue)
                    formData.append('DiscountAmount', this.discountAmount);
                    formData.append('DiscountPercent', this.discountMode === 'percent' ? this.discountValue : 0);


                    formData.append('PaymentType', this.document.PaymentType)
                    formData.append('Amount', this.paymentItems.filter(item => !!item.UnitPrice && !!item.Quantity).reduce((acc, item) =>
                            (acc + item.UnitPrice * item.Quantity),
                        0))

                    formData.append('VatAmount', this.totalVAT)
                    formData.append('TotalAmount', this.finalTotal)


                    formData.append('quote_document_ids', this.document.QuoteDocuments.map(doc => doc.Id).join(","))
                    formData.append('quote_document_types', this.document.QuoteDocuments.map(doc => doc.Type).join(","))


                    for (let i = 0; i < this.paymentItems.length; i++) {
                        formData.append(`quote_items[${i}][id]`, this.paymentItems[i].Id ?? 0)
                        formData.append(`quote_items[${i}][Title]`, this.paymentItems[i].Label)
                        formData.append(`quote_items[${i}][UnitPrice]`, this.paymentItems[i].UnitPrice)
                        formData.append(`quote_items[${i}][Quantity]`, this.paymentItems[i].Quantity)
                        formData.append(`quote_items[${i}][Amount]`, this.paymentItems[i].Quantity * this.paymentItems[i].UnitPrice)
                        formData.append(`quote_items[${i}][VatAmount]`, this.paymentItems[i].VatPercent * this.itemSubTotal(this.paymentItems[i], i)/100)
                        formData.append(`quote_items[${i}][VatPercent]`, this.paymentItems[i].VatPercent)

                        formData.append(`quote_items[${i}][TotalAmount]`, (!!this.paymentItems[i].Quantity && !!this.paymentItems[i].UnitPrice) ? this.paymentItems[i].Quantity * this.paymentItems[i].UnitPrice * (1 + (this.paymentItems[i].VatPercent ?? 0) / 100) + "" : "0")
                        formData.append(`quote_items[${i}][Currency]`, this.document.Currency)

                        if(this.discountAmount){
                            formData.append(`quote_items[${i}][DiscountAmount]`, this.discounts[i])
                        }
                    }

                    this.$http.post(
                        'quotes', formData
                    ).then((res) => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(res),
                                icon: 'InfoIcon',
                                variant: 'success',
                            },
                        })

                        router.push({name: 'quotes'})
                    })
                        .catch((err) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'InfoIcon',
                                    variant: 'danger',
                                },
                            })
                        })
                }
            })
        },

        onDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure you want to delete this entry?`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Confirm`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(() => {
                this.$swal({
                    icon: 'success',
                    title: this.i18nT(`Deleted!`),
                    text:  this.i18nT(`Selected quote(s) were deleted!`),
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
                router.push({name: 'quotes'})
            })
        },
        setup() {
            const vm = getCurrentInstance()
            return {vm}
        },
        addItems() {
            this.paymentItems.push({
                Quantity: 1,
                UnitPrice: 0,
                Total: 0,
                TotalAmount: 0,
                VatPercent: 0,
                VatAmount: 0,
            })
        }
    },
    watch: {
        file: function (val) {
            if (val) {
                this.file = val
                this.fileName = val.name
            } else {
                this.file = null
                this.fileName = ''
            }
        },
        paymentItems: {
            deep: true,
            handler: function (val) {
                this.paymentItems = this.updateTotals(val)
                this.updateTotalVat();
                this.calcActualDiscount();
            }
        },
        discountValue: function () {
            this.calcActualDiscount();
        },
        discountAmount: function () {
            this.paymentItems = this.updateTotals(this.paymentItems)
            this.updateTotalVat();
        },
        vendorId(newId, oldId){
            if(parseInt(newId) && newId !== oldId){
                this.$http.get(`vendors?id=${newId}`).then(({ data }) => {
                    this.selectedVendor = data.data;
                    if(this.selectedVendor){
                        if(this.selectedVendor.Contacts.length > 0){
                            this.document.RecipientEmail = this.document.RecipientName ? this.document.RecipientName : this.selectedVendor.Contacts[0].Mail
                            this.document.RecipientName = this.document.RecipientEmail ? this.document.RecipientEmail : this.selectedVendor.Contacts[0].Name
                        } else {
                            this.document.RecipientName = this.document.RecipientName ? this.document.RecipientName : this.selectedVendor.LegalRepresentative
                            this.document.RecipientEmail = this.document.RecipientEmail ? this.document.RecipientEmail : this.selectedVendor.BusinessEmail
                        }
                        this.document = {...this.document}

                    }
                });
            }
        },
        dropzonePopulateFromResp(newVal) {
            if(newVal && this.tempQuote){
                this.postProcessInvoice(this.tempQuote)
            }else if (!newVal && this.originalQuote){
                this.postProcessInvoice(this.originalQuote)
            }
        }
    },
}
</script>

<style lang="scss">

.mini-paddings {
    padding: 2px 4px;
}

.dicountIcon {
    position: relative;
    margin: 0 auto;
    margin-top: calc(50% - 1px);
}

.corners-straight-right {
    border-radius: 8px 0 0 8px;
    border-right:none;
}

.semi-button {
    border: 1px solid var(--gray);
    background: var(--primary);
    width: 64px;
    height: 38px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 0 8px 8px 0;
}
.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}
</style>
